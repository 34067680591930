import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const settings = namespace('settings');

@Component
export default class ReportingMixin extends Vue {
  @settings.Getter('getUserMode')
  userMode!: string;

  get searchText() {
    return this.$store.state.reporting.searchText || '';
  }

  set searchText(value: string) {
    this.$store.commit('reporting/setSearchText', value);
  }

  get teacherSearchText() {
    return this.$store.state.reporting.teacherSearchText || '';
  }

  set teacherSearchText(value: string) {
    this.$store.commit('reporting/setTeacherSearchText', value);
  }

  get lessonListSearchText() {
    return this.$store.state.reporting.lessonListSearchText || '';
  }

  set lessonListSearchText(value: string) {
    this.$store.commit('reporting/setLessonListSearchText', value);
  }

  get showStandardLessons() {
    return this.$store.state.reporting.showStandardLessons;
  }

  set showStandardLessons(val: boolean) {
    this.$store.commit('reporting/setShowStandardLessons', val);
  }

  get showStandardTeachers() {
    return this.userMode === 'A' && this.teacherId === 0;
  }

  get teacherId() {
    return this.$store.state.reporting.teacherId;
  }

  set teacherId(val: number) {
    this.$store.commit('reporting/setTeacherId', val);
  }

  created() {
    this.searchText = '';
    this.lessonListSearchText = '';
    this.teacherSearchText = '';
    this.teacherId = 0;
    this.showStandardLessons = false;
  }
}
